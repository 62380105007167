// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-header-container .MuiBadge-badge {
  right: -10px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKNavbar/style.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR","sourcesContent":[".ck-header-container {\n    .MuiBadge-badge {\n        right: -10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
