// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-artist-card {
  height: 170px;
  transition: all 0.2s ease-in-out;
}
.ck-artist-card h5 {
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ck-artist-card h6 {
  font-size: 0.7rem;
}
.ck-artist-card .ck-artist-description {
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 0.8rem;
}
.ck-artist-card .ck-artist-picture {
  aspect-ratio: 1/1;
  object-fit: cover;
}
.ck-artist-card:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.ck-artist-card .ck-artist-read-more {
  text-align: end;
  position: absolute;
  bottom: 0px;
  right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKArtistCard/style.scss"],"names":[],"mappings":"AAAA;EA2BI,aAAA;EACA,gCAAA;AAzBJ;AAFI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAIR;AADI;EACI,iBAAA;AAGR;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;EACA,oBAAA;EACA,4BAAA;EACA,iBAAA;AAER;AACI;EACI,iBAAA;EACA,iBAAA;AACR;AAKI;EACI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAHR;AAMI;EACI,eAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AAJR","sourcesContent":[".ck-artist-card {\n    h5 {\n        font-size: 1rem;\n        overflow: hidden;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n    }\n\n    h6 {\n        font-size: 0.7rem;\n    }\n\n    .ck-artist-description {\n        margin-top: 0.5rem;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        -webkit-line-clamp: 4;\n        display: -webkit-box;\n        -webkit-box-orient: vertical;\n        font-size: 0.8rem;\n    }\n\n    .ck-artist-picture {\n        aspect-ratio: 1/1;\n        object-fit: cover;\n    }\n\n    height: 170px;\n    transition: all 0.2s ease-in-out;\n\n    &:hover {\n        transform: scale(1.05);\n        cursor: pointer;\n        z-index: 100;\n        position: relative;\n    }\n\n    .ck-artist-read-more {\n        text-align: end;\n        position: absolute;\n        bottom: 0px;\n        right: 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
