// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-number-selector {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.ck-number-selector input {
  text-align: center;
}
.ck-number-selector input::-webkit-outer-spin-button,
.ck-number-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ck-number-selector input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/components/Generic/CKNumberSelector/style.scss"],"names":[],"mappings":"AAAA;EAKI,gCAAA;EAOA,YAAA;AATJ;AAFI;EACI,kBAAA;AAIR;AAAI;;EAEA,wBAAA;EACA,SAAA;AAEJ;AAEI;EACA,0BAAA;AAAJ","sourcesContent":[".ck-number-selector {\n    input {\n        text-align: center;;\n    }\n\n    /* Chrome, Safari, Edge, Opera */\n    input::-webkit-outer-spin-button,\n    input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n    }\n\n    /* Firefox */\n    input[type=number] {\n    -moz-appearance: textfield;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
