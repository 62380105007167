// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-floating-container {
  position: fixed !important;
  padding: 3rem 0;
  bottom: 0;
  width: 100%;
  transition: opacity 0.3s ease, max-height 3s ease;
}

.ck-floating-container-dropback {
  background-color: rgba(255, 255, 255, 0.6666666667);
}`, "",{"version":3,"sources":["webpack://./src/components/Generic/CKFloatingContainer/style.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,iDAAA;AACJ;;AAEA;EACI,mDAAA;AACJ","sourcesContent":[".ck-floating-container {\n    position: fixed !important;\n    padding: 3rem 0;\n    bottom: 0;\n    width: 100%;\n    transition: opacity 0.3s ease, max-height 3s ease;\n}\n\n.ck-floating-container-dropback {\n    background-color: #ffffffaa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
