// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-my-calendar {
  margin: 0px !important;
  width: 100% !important;
  max-height: 390px !important;
  height: 390px !important;
}
.ck-my-calendar .MuiPickersCalendarHeader-label {
  font-size: 1rem;
  color: rgba(52, 71, 103, 0.6666666667);
}
.ck-my-calendar .Mui-selected {
  background-color: rgba(26, 115, 232, 0.6666666667) !important;
}
.ck-my-calendar .ck-event-day {
  background-color: rgba(26, 115, 232, 0.2);
}
.ck-my-calendar .ck-calendar-day {
  margin: 0.3rem;
}
.ck-my-calendar .MuiDayCalendar-weekDayLabel {
  margin: 0.3rem;
  color: #1a73e8;
}
.ck-my-calendar .MuiPickersSlideTransition-root {
  overflow: hidden;
  height: 300px;
}

.ck-calendar-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/CKCalendar/style.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,sBAAA;EACA,4BAAA;EACA,wBAAA;AACJ;AACI;EACI,eAAA;EACA,sCAAA;AACR;AAEI;EACI,6DAAA;AAAR;AAGI;EACI,yCAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EACI,cAAA;EACA,cAAA;AAHR;AAMI;EACI,gBAAA;EACA,aAAA;AAJR;;AAQA;EACI,WAAA;AALJ","sourcesContent":[".ck-my-calendar {\n    margin: 0px !important;\n    width: 100% !important;\n    max-height: 390px !important;\n    height: 390px !important;\n\n    .MuiPickersCalendarHeader-label {\n        font-size: 1rem;\n        color: #344767aa;\n    }\n\n    .Mui-selected {\n        background-color: #1a73e8aa !important;\n    }\n\n    .ck-event-day {\n        background-color: #1a73e833;\n    }\n\n    .ck-calendar-day {\n        margin: 0.3rem;\n    }\n\n    .MuiDayCalendar-weekDayLabel {\n        margin: 0.3rem;\n        color: #1a73e8;\n    }\n\n    .MuiPickersSlideTransition-root {\n        overflow: hidden;\n        height: 300px;\n    }\n}\n\n.ck-calendar-container {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
