// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-settings-dropdown-menu .MuiListItemText-primary {
  font-size: 1rem !important;
}

.ck-hidden-settings-item {
  position: absolute;
  top: -10000px;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/CKSettingsButton/style.scss"],"names":[],"mappings":"AAMI;EACI,0BAAA;AALR;;AASA;EACI,kBAAA;EACA,aAAA;AANJ","sourcesContent":[".ck-settings-button {\n    // border-color: transparent !important;\n    // padding: 0px !important;\n}\n\n.ck-settings-dropdown-menu {\n    .MuiListItemText-primary {\n        font-size: 1rem !important;\n    }\n}\n\n.ck-hidden-settings-item {\n    position: absolute;\n    top: -10000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
