// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-lineup-avatar:hover {
  transform: scale(1.2);
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.event-lineup-avatar img {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKLineupDetail/style.scss"],"names":[],"mappings":"AACI;EACI,qBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAAR;AAGI;EACI,uBAAA;AADR","sourcesContent":[".event-lineup-avatar {\n    &:hover {\n        transform: scale(1.2);\n        cursor: pointer;\n        z-index: 100;\n        position: relative;\n    }\n\n    img {\n        height: 100% !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
