// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-row {
  border-radius: 1rem;
}
.event-row .event-row-logo {
  height: 100px;
  max-width: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.event-row .event-row-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 96px;
}
.event-row:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 100;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKEventRow/style.scss"],"names":[],"mappings":"AAAA;EAwBI,mBAAA;AAtBJ;AADI;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,wBAAA;AAGR;AAAI;EACI,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,aAAA;EACA,4BAAA;EACA,YAAA;AAER;AACI;EACI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACR","sourcesContent":[".event-row {\n    .event-row-logo {\n        height: 100px;\n        max-width: 100%;\n        object-fit: contain;\n        mix-blend-mode: multiply;\n    }\n\n    .event-row-description {\n        overflow: hidden;\n        display: -webkit-box;\n        -webkit-line-clamp: 4;\n        /* number of lines to show */\n        line-clamp: 4;\n        -webkit-box-orient: vertical;\n        height: 96px;\n    }\n\n    &:hover {\n        transform: scale(1.05);\n        cursor: pointer;\n        z-index: 100;\n        position: relative;\n    }\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
