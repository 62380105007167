// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-select-container:hover {
  cursor: pointer;
}
.ck-select-container .ck-select-input {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CKSelect/style.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAGI;EACI,wCAAA;AADR","sourcesContent":[".ck-select-container {\n    &:hover {\n        cursor: pointer;\n    }\n\n    .ck-select-input {\n        background-color: transparent !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
