import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface Props {
    children: any
}
const CKFormHeader: FC<Props> = (props) => {
    return (
        <MKBox
            variant="gradient"
            bgColor="info"
            coloredShadow="info"
            borderRadius="lg"
            p={2}
            mx={2}
            mt={-3}
        >
            <MKTypography variant="h3" color="white">
                {props.children}
            </MKTypography>
        </MKBox>
    )
}
export default CKFormHeader
