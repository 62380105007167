// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-dark-mode-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.ck-dark-mode-toggle:hover {
  box-shadow: none;
}

.ck-dark-mode-toggle:focus {
  box-shadow: none !important;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/CKDarkModeToggle/style.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EAEA,uBAAA;EACA,2BAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAEA;EACI,2BAAA;EACA,YAAA;AACJ","sourcesContent":[".ck-dark-mode-toggle {\n    background-color: transparent !important;\n    // width: 20px !important;\n    border: none !important;\n    box-shadow: none !important;\n}\n\n.ck-dark-mode-toggle:hover {\n    box-shadow: none;\n}\n.ck-dark-mode-toggle:focus {\n    box-shadow: none !important;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
