// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-modal-box {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 700px;
  transform: translate(-50%, -50%);
  border-radius: 0.65rem;
  box-shadow: 24;
  margin-top: 0px !important;
  max-width: 100%;
  margin: 0px !important;
  max-height: 70vh;
}

.ck-modal-close-btn {
  background-color: transparent !important;
  position: absolute !important;
  top: 3px;
  right: 3px;
  box-shadow: none !important;
}

.ck-modal-box:focus-visible {
  outline: none;
}

@media screen and (max-width: 768px) {
  .ck-modal-box {
    padding: 1rem !important;
    width: 100%;
    max-height: 90vh;
    margin-top: 0px !important;
    height: 100vh;
  }
  .ck-modal-badge {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKModal/styles.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,gCAAA;EAGA,sBAAA;EACA,cAAA;EACA,0BAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,wCAAA;EACA,6BAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI;IACI,wBAAA;IACA,WAAA;IACA,gBAAA;IACA,0BAAA;IACA,aAAA;EADN;EAIE;IACI,wBAAA;EAFN;AACF","sourcesContent":[".ck-modal-box {\n    position: absolute !important;\n    top: 50%;\n    left: 50%;\n    width: 700px;\n    transform: translate(-50%, -50%);\n    // width: 400,\n    // background-color: #fff !important;\n    border-radius: 0.65rem;\n    box-shadow: 24;\n    margin-top: 0px !important;\n    max-width: 100%;\n    margin: 0px !important;\n    max-height: 70vh;\n}\n\n.ck-modal-close-btn {\n    background-color: transparent !important;\n    position: absolute !important;\n    top: 3px;\n    right: 3px;\n    box-shadow: none !important;\n}\n\n.ck-modal-box:focus-visible {\n    outline: none;\n}\n\n@media screen and (max-width: 768px) {\n    .ck-modal-box {\n        padding: 1rem !important;\n        width: 100%;\n        max-height: 90vh;\n        margin-top: 0px !important;\n        height: 100vh;\n    }\n\n    .ck-modal-badge {\n        display: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
