// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-location-map-container {
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .ck-location-map-container {
    padding: 1rem;
    aspect-ratio: 16/9;
  }
}
@media screen and (max-width: 768px) {
  .ck-location-map-container {
    height: 70vh;
    margin: 0px -3rem;
    width: 100vw;
  }
}
.ck-location-marker {
  background-color: white;
  width: 40px;
  height: 40px;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.ck-location-marker:hover {
  transform: scale(1.2);
  cursor: pointer;
  z-index: 100;
}
.ck-location-marker img {
  width: 100%;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKLocationMapOverview/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI;IACI,aAAA;IACA,kBAAA;EACN;AACF;AAEA;EACI;IACI,YAAA;IACA,iBAAA;IACA,YAAA;EAAN;AACF;AAGA;EACI,uBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;AADJ;AAGI;EACI,qBAAA;EACA,eAAA;EACA,YAAA;AADR;AAII;EACI,WAAA;EACA,kBAAA;AAFR","sourcesContent":[".ck-location-map-container {\n    width: 100%;\n    max-width: 1920px;\n    display: flex;\n    justify-content: center;\n}\n\n@media screen and (min-width: 768px) {\n    .ck-location-map-container {\n        padding: 1rem;\n        aspect-ratio: 16/9;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .ck-location-map-container {\n        height: 70vh;\n        margin: 0px -3rem;\n        width: 100vw;\n    }\n}\n\n.ck-location-marker {\n    background-color: white;\n    width: 40px;\n    height: 40px;\n    padding: 2px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: all 0.2s ease-in-out;\n\n    &:hover {\n        transform: scale(1.2);\n        cursor: pointer;\n        z-index: 100;\n    }\n\n    img {\n        width: 100%;\n        border-radius: 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
