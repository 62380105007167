// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-edit-button svg {
  font-size: 1rem !important;
}
.ck-edit-button .ck-edit-icon {
  font-size: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/CKEditModelButton/style.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;AAAR;AAEI;EACI,0BAAA;AAAR","sourcesContent":[".ck-edit-button {\n    svg {\n        font-size: 1rem !important;\n    }\n    .ck-edit-icon {\n        font-size: 1rem !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
