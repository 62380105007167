// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-coffee-banner {
  width: 100%;
  display: none;
  text-align: center !important;
  position: fixed;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.9333333333);
  padding: 5px 0px;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .ck-coffee-banner {
    font-size: 0.8rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKCoffee/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;EACA,WAAA;EAEA,mDAAA;EACA,gBAAA;EACA,eAAA;AAAJ;;AAEA;EACI;IACI,4BAAA;EACN;AACF","sourcesContent":[".ck-coffee-banner {\n    width: 100%;\n    display: none;\n    text-align: center !important;\n    position: fixed;\n    bottom: 0px;\n    // background: linear-gradient(195deg, #EBEFF4, #CED4DA);\n    background-color: #ffffffee;\n    padding: 5px 0px;\n    font-size: 1rem;\n}\n@media screen and (max-width: 768px) {\n    .ck-coffee-banner {\n        font-size: 0.8rem !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
