// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-list-logo {
  object-fit: contain;
  width: 50px;
  height: 50px;
}

.ck-event-list-item:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 100;
  position: relative;
}

.ck-event-list-item {
  transition: all 0.2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKEventList/style.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AACA;EACI,gCAAA;AAEJ","sourcesContent":[".event-list-logo {\n    object-fit: contain;\n    width: 50px;\n    height: 50px;\n}\n\n.ck-event-list-item:hover {\n    transform: scale(1.05);\n    cursor: pointer;\n    z-index: 100;\n    position: relative;\n}\n.ck-event-list-item {\n    transition: all 0.2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
