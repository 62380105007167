// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scan-alert {
  position: absolute !important;
  z-index: 100;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Events/PageEventTicketScanner/style.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;EACA,gBAAA;AACJ","sourcesContent":[".scan-alert {\n    position: absolute !important;\n    z-index: 100;\n    width: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    max-width: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
