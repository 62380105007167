// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-page-badge-container img {
  height: 100% !important;
}

.ck-page-content {
  min-height: 60vh;
}

.ck-page-title {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .ck-page-title {
    font-size: 1.75rem !important;
    display: flex;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKPageContent/styles.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI;IACI,6BAAA;IACA,aAAA;IACA,sBAAA;EACN;AACF","sourcesContent":[".ck-page-badge-container img {\n    height: 100% !important;\n}\n\n.ck-page-content {\n    min-height: 60vh;\n}\n\n.ck-page-title {\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n}\n\n@media screen and (max-width: 768px) {\n    .ck-page-title {\n        font-size: 1.75rem !important;\n        display: flex;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
