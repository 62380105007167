// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-organisation-compact-container {
  transition: all 0.2s ease-in-out;
  text-align: center !important;
}
.ck-organisation-compact-container:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.ck-organisation-compact-container .ck-organisation-compact-banner {
  height: 100px;
  object-fit: contain;
  margin: 0px;
}
.ck-organisation-compact-container .ck-organisation-compact-badge {
  margin: auto;
  margin-top: -30px;
  background-color: rgba(255, 255, 255, 0.8);
}
.ck-organisation-compact-container .ck-organisation-compact-badge img {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKOrganisationCard/style.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,6BAAA;AACJ;AACI;EACI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAAR;AAGI;EACI,YAAA;EACA,iBAAA;EACA,0CAAA;AADR;AAGQ;EACI,uBAAA;AADZ","sourcesContent":[".ck-organisation-compact-container {\n    transition: all 0.2s ease-in-out;\n    text-align: center !important;\n\n    &:hover {\n        transform: scale(1.05);\n        cursor: pointer;\n        z-index: 100;\n        position: relative;\n    }\n\n    .ck-organisation-compact-banner {\n        height: 100px;\n        object-fit: contain;\n        margin: 0px;\n    }\n\n    .ck-organisation-compact-badge {\n        margin: auto;\n        margin-top: -30px;\n        background-color: #ffffffcc;\n\n        img {\n            height: 100% !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
