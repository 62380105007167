// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-spot-edit-card {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.ck-spot-edit-card input {
  text-align: center;
}
.ck-spot-edit-card input::-webkit-outer-spin-button,
.ck-spot-edit-card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ck-spot-edit-card input[type=number] {
  -moz-appearance: textfield;
}
.ck-spot-edit-card .MuiInput-root {
  width: 50px;
}
.ck-spot-edit-card .spot-delete-btn {
  color: #f44335;
}
.ck-spot-edit-card .ck-spot-edit-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ck-spot-edit-card .MuiTypography-caption {
  color: #7b809a;
}
.ck-spot-edit-card .event-spot-card-header {
  display: flex;
}
.ck-spot-edit-card .event-spot-card-header:hover {
  cursor: pointer;
}
.ck-spot-edit-card .spot-collapse-btn {
  top: 0px;
  right: 0px;
  position: absolute;
}
.ck-spot-edit-card .spot-title {
  font-size: 0.75rem;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKSpotEditCard/style.scss"],"names":[],"mappings":"AAAA;EAKI,gCAAA;EAOA,YAAA;AATJ;AAFI;EACI,kBAAA;AAIR;AAAI;;EAEI,wBAAA;EACA,SAAA;AAER;AAEI;EACI,0BAAA;AAAR;AAGI;EACI,WAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAHR;AAMI;EACI,cAAA;AAJR;AAOI;EACI,aAAA;AALR;AAQI;EACI,eAAA;AANR;AASI;EACI,QAAA;EACA,UAAA;EACA,kBAAA;AAPR;AAUI;EACI,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AARR","sourcesContent":[".ck-spot-edit-card {\n    input {\n        text-align: center;\n    }\n\n    /* Chrome, Safari, Edge, Opera */\n    input::-webkit-outer-spin-button,\n    input::-webkit-inner-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n    }\n\n    /* Firefox */\n    input[type='number'] {\n        -moz-appearance: textfield;\n    }\n\n    .MuiInput-root {\n        width: 50px;\n    }\n\n    .spot-delete-btn {\n        color: #f44335;\n    }\n\n    .ck-spot-edit-input-container {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .MuiTypography-caption {\n        color: #7b809a;\n    }\n\n    .event-spot-card-header {\n        display: flex;\n    }\n\n    .event-spot-card-header:hover {\n        cursor: pointer;\n    }\n\n    .spot-collapse-btn {\n        top: 0px;\n        right: 0px;\n        position: absolute;\n    }\n\n    .spot-title {\n        font-size: 0.75rem;\n        text-transform: uppercase;\n        width: 100%;\n        text-align: center;\n        margin-bottom: 0.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
