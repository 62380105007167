// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-checkbox {
  margin-left: 0px;
}
.ck-checkbox .MuiCheckbox-root {
  padding: 5px;
}
.ck-checkbox .MuiFormControlLabel-label {
  font-weight: normal !important;
  font-size: 0.75rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CKCheckBox/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,8BAAA;EACA,6BAAA;AAAR","sourcesContent":[".ck-checkbox {\n    margin-left: 0px;\n\n    .MuiCheckbox-root {\n        padding: 5px;\n    }\n\n    .MuiFormControlLabel-label {\n        font-weight: normal !important;\n        font-size: 0.75rem !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
