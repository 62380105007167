// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-lineup-toggle:hover {
  cursor: pointer;
}

.ck-lineup-toggle {
  width: 50px;
}

.ck-lineup-user-name {
  font-size: 0.9rem;
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ck-table-head {
  font-size: 1rem;
  font-weight: 500;
  color: #1a73e8;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKLineupEdit/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AACJ","sourcesContent":[".ck-lineup-toggle:hover {\n    cursor: pointer;\n}\n\n.ck-lineup-toggle {\n    width: 50px;\n}\n\n.ck-lineup-user-name {\n    font-size: 0.9rem;\n    margin: 0px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.ck-table-head {\n    font-size: 1rem;\n    font-weight: 500;\n    color: #1a73e8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
