import { Card, Grid, ListItemIcon, ListItemText, MenuItem, Switch } from '@mui/material'
import CKSearchBar from 'components/Form/CKSearchBar'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKBadge from 'components/MaterialKit/MKBadge'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import {
    CKModal,
    CKModalBadge,
    CKModalBody,
    CKModalHeader,
    CKModalSubtitle,
    CKModalTitle,
} from 'components/UI/CKModal'
import CKModel from 'models/CKModel'
import Image from 'models/Image'
import User from 'models/User'
import React, { FC, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClipLoader from 'react-spinners/ClipLoader'
import AccessService from 'services/AccessService'
import UserService from 'services/UserService'
import './style.scss'

// Icons
import {
    ClearIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    ManageAccountsIcon,
    SaveIcon,
} from 'components/UI/CKIcons'
interface Props {
    model: CKModel
    menuItem?: boolean
    logo?: Image
}
const CKElevatedUsers: FC<Props> = (props) => {
    const [possibilities, setPossibilities] = useState<any[]>()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])
    const [knownUsers, setKnownUsers] = useState([])

    const [cardOpen, setCardOpen] = useState<string | undefined>()
    const [searchTerm, setSearchTerm] = useState('')

    const init = async () => {
        try {
            const resp = await AccessService.getPossibilities(props.model)
            setPossibilities(resp)

            const _users = await AccessService.getElevatedUsers(props.model)

            setKnownUsers(_users)
            setDefaultUsers(_users)
        } catch (e) {
            console.error(e)
        }
    }

    const buttonOnClick = async () => {
        setLoading(true)
        await init()
        setSearchTerm('')
        setCardOpen(undefined)
        setOpen(true)
        setLoading(false)
    }

    useEffect(() => { }, [])

    const renderButton = () => {
        if (props.menuItem) {
            return (
                <MenuItem onClick={buttonOnClick}>
                    <ListItemIcon>
                        <ManageAccountsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Beheerders</ListItemText>
                </MenuItem>
            )
        } else {
            return (
                <MKButton variant="gradient" color="info" onClick={buttonOnClick}>
                    {loading ? (
                        <ClipLoader size={15} color={'white'} className="me-2" />
                    ) : (
                        <ManageAccountsIcon className="me-2" />
                    )}
                    Beheerders
                </MKButton>
            )
        }
    }

    const toggleAccess = (user: User, rightId: string) => {
        const _knownUsers = Array.from(knownUsers)
        let index = _knownUsers.findIndex((a) => a.user_id === user.$id)
        if (index < 0) {
            _knownUsers.push({
                rights: [],
                user_id: user.$id,
            })
        }
        index = _knownUsers.findIndex((a) => a.user_id === user.$id)

        const rights: string[] = _knownUsers[index].rights
        if (rights.includes(rightId)) {
            rights.splice(rights.indexOf(rightId), 1)
        } else {
            _knownUsers[index].rights = [rightId]
        }

        setKnownUsers(_knownUsers)
    }

    const setDefaultUsers = (_knownUsers = knownUsers) => {
        const _users = _knownUsers.map((known) => {
            return known.user
        })
        setUsers(_users)
    }

    const fetchUsers = async (page = 1, search = searchTerm) => {
        if (search) {
            const resp = await UserService.index(search, page)
            setUsers(resp.data)
        } else {
            setDefaultUsers()
        }
    }

    const renderUserCard = (user: User) => {
        let rights = knownUsers.filter((a) => a.user_id === user.$id)
        if (rights && rights.length > 0) {
            rights = rights[0].rights
        }

        return (
            <Card className="ck-elevated-user-card mb-2" key={'elevated-user-card-' + user.$id}>
                <Grid
                    container
                    spacing={0}
                    className="ck-elevated-user-card-header"
                    onClick={() => {
                        if (cardOpen === user.$id) {
                            setCardOpen(undefined)
                        } else {
                            setCardOpen(user.$id)
                        }
                    }}
                >
                    <Grid item xs={rights && rights.length > 0 ? 7 : 11}>
                        <div className="d-flex align-items-center p-2">
                            <MKAvatar
                                src={user.$picture && user.$picture.$url}
                                size="sm"
                                className="me-2"
                            />
                            <p className="ck-elevated-user-name">
                                {user.$first_name} {user.$last_name}
                            </p>
                        </div>
                    </Grid>
                    {rights && rights.length > 0 && (
                        <Grid item xs={4} className="d-flex align-items-center justify-content-end">
                            <MKBadge
                                color={
                                    possibilities &&
                                        possibilities.find((a) => a.id === rights[0]).color
                                        ? possibilities.find((a) => a.id === rights[0]).color
                                        : 'info'
                                }
                                badgeContent={
                                    possibilities &&
                                    possibilities.find((a) => a.id === rights[0]).short_name
                                }
                                container
                            />
                        </Grid>
                    )}
                    <Grid item xs={1} className="d-flex align-items-center justify-content-center">
                        {cardOpen === user.$id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Grid>
                </Grid>
                {cardOpen === user.$id && (
                    <Grid container className="pb-2 px-2">
                        {possibilities &&
                            possibilities.map((pos, posIndex) => {
                                const checked =
                                    rights && (rights.includes(pos.id) || rights.includes('FULL'))
                                return (
                                    <React.Fragment key={'user-' + user.$id + '-pos-' + posIndex}>
                                        <Grid item xs={10}>
                                            <div className="text-start px-2">
                                                <MKTypography
                                                    variant="button"
                                                    fontWeight="bold"
                                                    color="info"
                                                    className="w-100"
                                                >
                                                    {pos.name}
                                                </MKTypography>
                                                <MKTypography component="div" variant="button">
                                                    {pos.description}
                                                </MKTypography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Switch
                                                checked={checked}
                                                color="info"
                                                disabled={
                                                    rights.includes('FULL') && pos.id !== 'FULL'
                                                }
                                                onChange={() => {
                                                    toggleAccess(user, pos.id)
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                    </Grid>
                )}
            </Card>
        )
    }

    const save = async () => {
        const resp = await AccessService.updateElevatedUsers(props.model, knownUsers)
        setKnownUsers(resp)
        setDefaultUsers(resp)
    }

    const renderModal = () => {
        return (
            <CKModal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                closeButton
            >
                <CKModalHeader className="mb-3">
                    {props.logo && <CKModalBadge src={props.logo && props.logo.$url} />}
                    <CKModalTitle>{props.model.$name && props.model.$name}</CKModalTitle>
                    <CKModalSubtitle>Beheerders</CKModalSubtitle>
                </CKModalHeader>
                <CKModalBody>
                    <div>
                        <CKSearchBar
                            defaultValue={searchTerm}
                            onSearch={(e) => {
                                setSearchTerm(e)
                                fetchUsers(1, e)
                            }}
                        />
                    </div>
                    <div className="px-2 py-3">
                        <div className="text-center" style={{ height: '50vh' }}>
                            {(!users || users.length === 0) &&
                                (!knownUsers || knownUsers.length === 0) &&
                                !searchTerm && (
                                    <MKTypography variant="body2">
                                        Voeg een beheerder toe door de gebruiker op te zoeken...
                                    </MKTypography>
                                )}
                            {(!users || users.length === 0) && searchTerm && (
                                <MKTypography variant="body2">
                                    Geen gebruiker gevonden...
                                </MKTypography>
                            )}
                            {users && users.length > 0 && (
                                <PerfectScrollbar style={{ height: '100%' }}>
                                    {users &&
                                        users.map(renderUserCard)}
                                </PerfectScrollbar>
                            )}
                        </div>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <MKButton
                                variant="gradient"
                                color="info"
                                className="w-100"
                                onClick={async () => {
                                    await save()
                                    setOpen(false)
                                }}
                            >
                                <SaveIcon className="me-2" />
                                Opslaan
                            </MKButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MKButton
                                variant="outlined"
                                color="info"
                                className="w-100"
                                onClick={() => {
                                    init()
                                    setOpen(false)
                                }}
                            >
                                <ClearIcon className="me-2" />
                                Annuleren
                            </MKButton>
                        </Grid>
                    </Grid>
                </CKModalBody>
            </CKModal>
        )
    }

    return (
        <>
            {props.model && props.model.$id && (
                <>
                    {renderButton()}
                    {renderModal()}
                </>
            )}
        </>
    )
}
export default CKElevatedUsers
