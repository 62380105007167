// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* box-shadow: 0rem 0.3125rem 0.625rem 0rem rgba(0, 0, 0, 0.12);
  background: linear-gradient(285deg, #42424a, #191919) !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.map-container {
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    padding: 0px !important;
    max-width: 100% !important;
    margin: 0px !important;
  }
  .navbar-box {
    border-radius: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,SAAA;EACA,8JAAA;EAEA,mCAAA;EACA,kCAAA;EACA,iBAAA;EACA;qEAAA;AAFJ;;AAMA;EACI,+EAAA;AAHJ;;AAMA;EACI,YAAA;EACA,WAAA;EACA,sBAAA;AAHJ;;AAMA;EACI;IACI,uBAAA;IACA,0BAAA;IACA,sBAAA;EAHN;EAME;IACI,6BAAA;EAJN;AACF","sourcesContent":["//Components\n@use 'styles/components/CKImagePicker.scss';\n\nbody {\n    overflow-x: hidden;\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    min-height: 100vh;\n    /* box-shadow: 0rem 0.3125rem 0.625rem 0rem rgba(0, 0, 0, 0.12);\n  background: linear-gradient(285deg, #42424a, #191919) !important; */\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.map-container {\n    height: 100%;\n    width: 100%;\n    border-radius: 0.75rem;\n}\n\n@media screen and (max-width: 768px) {\n    .navbar-container {\n        padding: 0px !important;\n        max-width: 100% !important;\n        margin: 0px !important;\n    }\n\n    .navbar-box {\n        border-radius: 0px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
