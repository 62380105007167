// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: inherit;
}

.overlay-container:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.ck-image-upload .btn--upload {
  line-height: 1rem !important;
  padding: 1rem 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CKImageUpload/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA,EAAA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,6BAAA;EACA,sBAAA;AACJ;;AAEA;EACI,UAAA;EACA,eAAA;AACJ;;AAGI;EACI,4BAAA;EACA,kBAAA;AAAR","sourcesContent":[".overlay-container {\n    position: relative;\n}\n\n.overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* Overlay color */\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    opacity: 0;\n    transition: opacity 0.3s ease;\n    border-radius: inherit;\n}\n\n.overlay-container:hover .overlay {\n    opacity: 1;\n    cursor: pointer;\n}\n\n.ck-image-upload {\n    .btn--upload {\n        line-height: 1rem !important;\n        padding: 1rem 2rem;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
