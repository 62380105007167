// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-elevated-user-card {
  background-color: rgba(255, 255, 255, 0.5019607843) !important;
}
.ck-elevated-user-card .ck-elevated-user-name {
  font-size: 0.9rem;
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ck-elevated-user-card .ck-elevated-user-card-header {
  width: 100%;
}
.ck-elevated-user-card .ck-elevated-user-card-header:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/CKElevatedUsers/style.scss"],"names":[],"mappings":"AAAA;EACI,8DAAA;AACJ;AACI;EACI,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAEI;EACI,WAAA;AAAR;AAEI;EACI,eAAA;AAAR","sourcesContent":[".ck-elevated-user-card {\n    background-color: #ffffff80 !important;\n\n    .ck-elevated-user-name {\n        font-size: 0.9rem;\n        margin: 0px;\n        overflow: hidden;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n    }\n\n    .ck-elevated-user-card-header {\n        width: 100%;\n    }\n    .ck-elevated-user-card-header:hover {\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
