// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-address-btn:hover {
  cursor: pointer;
}

.ck-address-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ck-workshop-compact-container {
  transition: all 0.2s ease-in-out;
  text-align: center !important;
}
.ck-workshop-compact-container:hover {
  transform: scale(1.05);
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.ck-workshop-compact-container .ck-workshop-compact-banner {
  height: 100px;
  object-fit: contain;
  margin: 0px;
}
.ck-workshop-compact-container .ck-workshop-compact-badge {
  margin: auto;
  margin-top: -30px;
  background-color: rgba(255, 255, 255, 0.8);
}
.ck-workshop-compact-container .ck-workshop-compact-badge img {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKWorkshopCard/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,6BAAA;AACJ;AACI;EACI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAAR;AAGI;EACI,YAAA;EACA,iBAAA;EACA,0CAAA;AADR;AAGQ;EACI,uBAAA;AADZ","sourcesContent":[".ck-address-btn:hover {\n    cursor: pointer;\n}\n\n.ck-address-btn {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.ck-workshop-compact-container {\n    transition: all 0.2s ease-in-out;\n    text-align: center !important;\n\n    &:hover {\n        transform: scale(1.05);\n        cursor: pointer;\n        z-index: 100;\n        position: relative;\n    }\n\n    .ck-workshop-compact-banner {\n        height: 100px;\n        object-fit: contain;\n        margin: 0px;\n    }\n\n    .ck-workshop-compact-badge {\n        margin: auto;\n        margin-top: -30px;\n        background-color: #ffffffcc;\n\n        img {\n            height: 100% !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
