// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  .ck-form .ck-form-subtitle {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CKForm/style.scss"],"names":[],"mappings":"AACI;EACI;IACI,aAAA;IACA,sBAAA;IACA,kBAAA;IACA,uBAAA;IACA,WAAA;EAAV;AACF","sourcesContent":[".ck-form {\n    @media screen and (max-width: 768px) {\n        .ck-form-subtitle {\n            display: flex;\n            flex-direction: column;\n            text-align: center;\n            justify-content: center;\n            width: 100%;\n        }\n    }\n\n    @media screen and (min-width: 768px) {\n        .ck-form-title {\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
